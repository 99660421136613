/** @jsx jsx */

import { Link } from 'gatsby'
import { jsx } from 'theme-ui'

import { faces } from '../utilities'

const Footer = () => {
  const randomCharacterId = faces[Math.floor(Math.random() * faces.length)].number

  const sections = [
    {
      label: `Main`,
      links: [
        { key: `home`, children: `Home`, to: `/` },
        { key: `random`, children: `Random`, to: `/characters/${randomCharacterId}` },
        { key: `collections`, children: `Collections`, to: `/collections` },
      ],
    },

    {
      label: `Resources`,
      links: [
        { key: `about`, children: `About`, to: `/about` },
        { key: `opensea`, children: `OpenSea`, to: `https://opensea.io/collection/character-characters` },
      ],
    },

    {
      label: `Fun`,
      links: [
        { key: `cycle`, children: `Cycle`, to: `/cycle` },
        { key: `grid`, children: `Grid`, to: `/grid` },
      ],
    },

    // {
    //   label: `Follow`,
    //   links: [
    //     { key: `twitter`, children: `@characterchars (TW)`, to: `https://twitter.com/characterchars` },
    //     { key: `instagram`, children: `@charactercharacters (IG)`, to: `https://instagram.com/charactercharacters` },
    //   ],
    // },
  ]

  return (
    <footer
      sx={{
        px: [`0.5rem`, `0.75rem`],
        py: 4,
        bg: `footerBackground`,
      }}
    >
      <div sx={{ maxWidth: 800, mx: `auto` }}>
        <div
          sx={{
            display: `grid`,
            gap: 3,
            gridTemplateColumns: `repeat(3, 1fr)`,
          }}
        >
          {sections.map(({ label, links }) => {
            return (
              <div key={label}>
                <h1 sx={{ my: 0, fontSize: 2 }}>{label}</h1>

                <ul
                  sx={{
                    mb: 0,
                    mt: 3,
                    ml: -2,
                    pl: 0,
                    listStyleType: `none`,
                  }}
                >
                  {links.map(({ children, to }) => {
                    return (
                      <li key={children}>
                        <Link
                          sx={{
                            display: `inline-block`,
                            px: 2,
                            py: 1,
                            color: `action`,
                            textDecoration: `none`,

                            ':hover, :focus': {
                              textDecoration: `underline`,
                            },
                          }}
                          {...{ children, to }}
                        />
                      </li>
                    )
                  })}
                </ul>
              </div>
            )
          })}
        </div>

        <p sx={{ mt: 5, mb: 0 }}>
          Character characters was created in 2021–2022. More characters will become available overtime.
        </p>
      </div>
    </footer>
  )
}

export default Footer
