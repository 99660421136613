/** @jsx jsx */

import { jsx } from 'theme-ui'

import Footer from '../components/footer'
import Halo from '../components/halo'
import Header from '../components/header'
import { formatCollectionFace, getOptions } from '../utilities'

export const Collections = () => (
  <div sx={{ display: `grid`, fontFamily: `body`, gap: 3 }}>
    <Halo description="Explore characters by attribute." title="Collections" />
    <Header />

    <section sx={{ py: 5, px: [`0.5rem`, `0.75rem`], bg: `sectionBackground` }}>
      <div sx={{ maxWidth: 800, mx: `auto` }}>
        <h1
          sx={{
            my: 0,
            fontWeight: `600`,
            fontSize: [`2rem`, `2.5rem`, `3rem`],
            letterSpacing: `-0.02em`,
          }}
        >
          Collections
        </h1>

        <p sx={{ mt: 2, mb: 4, fontSize: [`1rem`, `1.25rem`, `1.5rem`] }}>Explore characters by attribute.</p>
      </div>
    </section>

    <main sx={{ py: 4, px: [`0.5rem`, `0.75rem`], bg: `sectionBackground` }}>
      <div sx={{ maxWidth: 800, mx: `auto` }}>
        <div
          sx={{
            my: 0,
            pl: 0,
            listStyleType: `none`,
            justifyContent: `space-between`,
            gap: `0.25rem`,
            display: `grid`,
            gridTemplateColumns: `repeat(auto-fill, minmax(130px, 1fr))`,
          }}
        >
          {getOptions().map(({ name }) => (
            <a
              key={name}
              href={`/collections/${name.toLowerCase().replace(` `, `-`)}`}
              sx={{
                display: `block`,
                textDecoration: `none`,
                color: `inherit`,

                ':focus': {
                  outline: `none`,
                },
              }}
            >
              <figure sx={{ m: 0 }}>
                <pre
                  sx={(theme) => ({
                    textAlign: `center`,
                    aspectRatio: `1`,
                    display: `grid`,
                    placeContent: `center`,
                    my: 0,
                    fontSize: `20px`, // `1.25em`,
                    fontFamily: `monospace`,
                    fontWeight: `500`,
                    lineHeight: `1.2`,
                    color: `characterPrimaryText`,
                    bg: `characterPrimaryBackground`,
                    boxShadow: `0 4px 0 -2px ${theme.colors.sectionBackground}, 0 6px 0 -2px ${theme.colors.characterPrimaryBackground}, 0 12px 0 -6px ${theme.colors.sectionBackground}, 0 14px 0 -6px ${theme.colors.characterPrimaryBackground}`,

                    'a:hover > figure > &, a:focus > figure > &': {
                      outline: `1px solid`,
                      outlineOffset: `-3px`,
                    },
                  })}
                >
                  {formatCollectionFace(name)}
                </pre>
              </figure>

              <figcaption
                sx={{
                  py: 3,
                  textAlign: `center`,

                  'a:hover > &, a:focus > &': {
                    textDecoration: `underline`,
                  },
                }}
              >{`${name[0].toLocaleUpperCase()}${name.slice(1)}`}</figcaption>
            </a>
          ))}
        </div>
      </div>
    </main>

    <Footer />
  </div>
)

export default Collections
