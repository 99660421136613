/** @jsx jsx */

import { Global } from '@emotion/react'
import { Link } from 'gatsby'
import { FC, Fragment } from 'react'
import { jsx, useColorMode } from 'theme-ui'

import { faces } from '../utilities'

const HeaderLink = ({ children, to }) => {
  return (
    <Link
      sx={{
        p: [`0.75rem`, `1rem`],
        color: `text`,
        textDecoration: `none`,
        display: [`About`, `OpenSea`].includes(children) ? [`none`, `inline-block`] : `inline-block`,
        ':hover': { textDecoration: `underline` },
      }}
      {...{ children, to }}
    />
  )
}

const Header: FC<{ style?: {} }> = ({ style = {} }) => {
  const [colorMode, setColorMode] = useColorMode()

  const randomCharacterId = faces[Math.floor(Math.random() * faces.length)].number

  const links = [
    { key: `random`, children: `Random`, to: `/characters/${randomCharacterId}` },
    { key: `collections`, children: `Collections`, to: `/collections` },
    { key: `about`, children: `About`, to: `/about` },
    { key: `openSea`, children: `OpenSea`, to: `https://opensea.io/collection/character-characters` },
  ]

  return (
    <Fragment>
      <Global
        styles={() => ({
          '*': { boxSizing: 'border-box' },
          body: { margin: 0 },
          pre: { fontFamily: `monospace`, lineHeight: 1.2 },
        })}
      />

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
      <link
        href="https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=IBM+Plex+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"
        rel="stylesheet"
      />

      <header
        sx={{
          my: 0,
          fontSize: `1rem`,
          p: [`0.5rem`, `0.75rem`],
          bg: `sectionBackground`,
          ...style,
        }}
      >
        <div
          sx={{
            maxWidth: 800,
            mx: `auto`,
            justifyContent: `space-between`,
            alignItems: `center`,
            display: `flex`,
            flexWrap: `wrap`,
          }}
        >
          <Link
            to="/"
            title="Character characters"
            sx={(theme) => ({
              boxSizing: `border-box`,
              fontFamily: `monospace`,
              display: `grid`,
              alignItems: `center`,
              aspectRatio: `1`,
              textDecoration: `none`,
              color: `black`,
              px: [`0.5rem`, `0.75rem`],
              bg: `var(--logo-background-color, ${theme.colors.characterPrimaryBackground})`,

              ':hover, :focus': {
                bg: colorMode === `dark` ? `#fff` : `#000`,
                color: colorMode === `dark` ? `#000` : `#fff`,
              },
            })}
          >
            c_C
          </Link>

          <div sx={{ display: `flex`, alignItems: `center` }}>
            {links.map((props) => (
              <HeaderLink {...props} />
            ))}

            <button
              onClick={() => setColorMode(colorMode === `default` ? `dark` : `default`)}
              aria-label="Toggle color mode"
              sx={{
                width: 48,
                height: 48,
                m: 0,
                border: 0,
                p: 0,
                lineHeight: 1,
                color: `text`,
                cursor: `pointer`,
                bg: `transparent`,
                appearance: `none`,

                ':hover': {
                  color: `action`,
                },
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="20"
                height="20"
                sx={{
                  border: `1px solid`,
                  borderRadius: `50%`,
                  bg: `currentcolor`,
                }}
              >
                <path
                  d="M14.53 10.53a7 7 0 01-9.058-9.058A7.003 7.003 0 008 15a7.002 7.002 0 006.53-4.47z"
                  sx={{
                    fill: 'background',
                    fillRule: 'evenodd',
                    clipRule: 'evenodd',
                  }}
                />
              </svg>
            </button>
          </div>
        </div>
      </header>
    </Fragment>
  )
}

export default Header
